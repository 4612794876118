@import '~antd/dist/antd.css';

/* 
FONTS
*/

@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand-Bold"),
    url("./assets/fonts/Quicksand-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand-Medium";
  src: local("Quicksand-Medium"),
    url("./assets/fonts/Quicksand-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand-Regular";
  src: local("Quicksand-Regular"),
    url("./assets/fonts/Quicksand-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand-SemiBold";
  src: local("Quicksand-SemiBold"),
    url("./assets/fonts/Quicksand-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand-Light";
  src: local("Quicksand-Light"),
    url("./assets/fonts/Quicksand-Light.ttf") format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.upk-default-content, .upk-profile-content, .upk-login-content, .upk-create-content::-webkit-scrollbar {
  display: none;  /* Chrome */
}
.upk-default-content, .upk-profile-content, .upk-login-content, .upk-create-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}